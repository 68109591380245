var generic = generic || {};

(function ($) {
  Drupal.behaviors.contentBlockLargeV1 = {
    attach: function (context) {
      var $content_block_large_content_texts = $('.content-block-large__content-text');

      $content_block_large_content_texts.each(function (index, text_content) {
        var allChildrenEmpty;
        var $text_content = $(text_content);

        allChildrenEmpty = true;
        $text_content.children().each(function (index, content) {
          if ($(content).html().trim()) {
            allChildrenEmpty = false;
          }
        });

        if (allChildrenEmpty) {
          $text_content.hide();
        }
      });

      var $modules = $('.js-content-block', context);

      $modules.each(function () {
        var $module = $(this);
        var $play = $('.js-video-play', $module);
        var $readMore = $('.js-read-more', $module);
        var $basicCarousel = $('.js-basic-carousel', context);
        var $inlineCarousel = $('.js-content-block-carousel', $module);
        var contentBgColor = $('.js-content-block', $basicCarousel).css('background-color');
        var vimeoID = '';
        var youtubeID = '';
        var $largeImage = '';

        $module.filter('.js-content-block--clickable').on('click.openClickable', function (e) {
          e.preventDefault();
          if (e.target !== e.currentTarget) {
            return;
          }
          if ($(this).attr('data-clickable') != '') {
            window.open($(this).data('clickable'), '_blank');
          }
        });

        if ($readMore.length) {
          $readMore.on('click.readMore', function (event) {
            event.preventDefault();
            $(this).slideUp();
            $('.content-block-large__content-text__content--2', $module).removeClass('hidden').hide().slideDown();
          });
        }

        if ($inlineCarousel.length) {
          $inlineCarousel.slick({
            infinite: false,
            arrows: true,
            prevArrow:
              '<span><svg role="button" tabindex="0" class="slick-arrow slick-prev"><use xlink:href="#arrow--left"></use></svg></span>',
            nextArrow:
              '<span><svg role="button" tabindex="0" class="slick-arrow slick-next"><use xlink:href="#arrow--right"></use></svg></span>'
          });
        }

        if ($module.hasClass('has-video')) {
          $play.on('click.openVideo', function (e) {
            e.preventDefault();
            $module.addClass('video-active');
            vimeoID = $module.find('.js-internetVideo').attr('data-vimeo-id');
            youtubeID = $module.find('.video-youtube').attr('data-youtube-id');
            if ((vimeoID && vimeoID.length) || (youtubeID && youtubeID.length)) {
              $largeImage = $module.find('.js-largeImage');
              if ($largeImage && $largeImage.length > 0) {
                $module.find('.js-extraPadding').parent().addClass('js-showVideo');
              } else {
                $module.find('.js-content-block-large-image').addClass('js-showVideo');
              }
              if (youtubeID && youtubeID.length) {
                $module.find('.js-play').trigger('click');
              }
            } else {
              if ($(this).hasClass('js-video-play--inline')) {
                var videoHTML = $module.find('.js-video-block').html();

                $module.find('.js-video-block').remove();
                $(this).parent().html(videoHTML);
                attachHandlers($module);
              } else {
                $module.find('.content-block-large__content-image, .js-video-play, picture').stop().fadeOut();
              }
              $module.find('video').get(0).play();
            }
          });

          if ($module.hasClass('video-muted') && $module.find('video').get(0)) {
            $module.find('video').get(0).muted = true;
          }

          if ($module.hasClass('video-loop')) {
            $module.find('video').get(0).loop = true;
          }

          if ($module.hasClass('video-autoplay')) {
            $play.trigger('click.openVideo');
          }

          if (!$module.hasClass('video-background')) {
            attachHandlers($module);
          }
        }
        if ($basicCarousel.length !== 0) {
          $basicCarousel.closest('.content').css('background-color', contentBgColor);
        }
      });

      function attachHandlers($elem) {
        $('video', $elem)
          .on('mouseenter.controls', function (e) {
            $(this).attr('controls', '');
          })
          .on('mouseleave.controls', function (e) {
            $(this).removeAttr('controls');
          });
      }

      // Allow btn to launch overlay
      $('.js-overlay').on('click', function (e) {
        e.preventDefault();
        var $overlayContent = $('.js-overlay-content');

        generic.overlay.launch({
          content: $overlayContent.html(),
          height: 304,
          width: 668,
          maxWidth: '100%',
          cssClass: 'overlay-content',
          scrolling: true,
          escKey: true
        });
      });

      $(document).on('cbox_complete', function () {
        $('.js-basic-overlay-cta').on('click', function () {
          generic.overlay.hide();
        });
      });
    }
  };
})(jQuery);
